import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//Import Components
import LanguageSelector from "./components/LanguageSelector";
import ThemeSwitcher from "./components/ThemeSwitcher";

//i18n
import i18n from "./i18n";
import { withTranslation } from "react-i18next";

//redux store
// import {  } from "./redux/actions";
//Import Images
import logodark from "./assets/images/logo-dark.png";
import logolight from "./assets/images/logo-light.png";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null,
    };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({
      hasError: error,
      errorMessage: info,
    });
  };

  routeChange = (path = null) => {
    window.location.href = path || "/";
  };

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <React.Fragment>
            <div className="account-pages mt-5 pt-sm-5 pt-md-0">
              <div className="faux-menu">
                <ThemeSwitcher />
                <LanguageSelector />
              </div>
              <Container>
                <Row className="justify-content-center">
                  <Col md={6} lg={4} xl={4} style={{ minWidth: "30vw" }}>
                    <div className="text-center mb-4">
                      <Link
                        to="/"
                        onClick={() => this.routeChange("/")}
                        className="auth-logo mb-2 d-block"
                      >
                        <img
                          src={logodark}
                          alt=""
                          height="50"
                          className="logo logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="50"
                          className="logo logo-light"
                        />
                      </Link>
                    </div>

                    <Card>
                      <CardBody className="p-4">
                        {this.state.hasError ? (
                          <Alert className="mb-0" color="danger">
                            {!process.env.NODE_ENV ||
                            process.env.NODE_ENV === "development"
                              ? this.state.hasError.toString()
                              : t("Error")}
                          </Alert>
                        ) : (
                          t("Error")
                        )}
                        <div className="p-3">
                          {!process.env.NODE_ENV ||
                          process.env.NODE_ENV === "development" ? (
                            this.state.errorMessage ? (
                              <p>{this.state.errorMessage.componentStack}</p>
                            ) : (
                              t(
                                "Sorry, we couldn't process your request. Please try again later."
                              )
                            )
                          ) : (
                            t(
                              "Sorry, we couldn't process your request. Please try again later."
                            )
                          )}
                          <br />
                          <div className="d-grid">
                            <Link
                              className="btn btn-block btn-primary"
                              to="/dashboard"
                              onClick={() => this.routeChange("/dashboard")}
                            >
                              {t("Dashboard")}
                            </Link>
                          </div>
                          <br />
                          <p>
                            {t("Do you need help verifying your account?")}{" "}
                            <br />
                            {t(
                              "Please contact the Chromesque support desk"
                            )}{" "}
                            <a href="mailto:support@chromesque.com">
                              support@chromesque.com
                            </a>
                          </p>
                        </div>
                      </CardBody>
                    </Card>

                    <div className="mt-5 text-center">
                      <p>
                        &copy;{this.props.currentYear} {t("Atchr")}{" "}
                        {t("by Chromesque")}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        )
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  const { currentYear } = state.Misc;
  return { currentYear };
};

export default connect(mapStateToProps, {})(withTranslation()(ErrorBoundary));
