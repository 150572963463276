export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_ROOM = "ADD_ROOM";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const SET_ENTITIES = "SET_ENTITIES";
export const DELETE_ENTITY = "DELETE_ENTITY";
export const SET_CONTACTS = "SET_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const BLOCK_CONTACT = "BLOCK_CONTACT";
export const UNBLOCK_CONTACT = "UNBLOCK_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_GUEST = "UPDATE_GUEST";
export const SET_GUESTS = "SET_GUESTS";
export const ADD_GUEST = "ADD_GUEST";
export const DELETE_GUEST = "DELETE_GUEST";
export const START_CHAT = "START_CHAT";
export const SET_RECIPIENT = "SET_RECIPIENT";
export const SET_USER_ONLINE = "SET_USER_ONLINE";
export const SET_USER_OFFLINE = "SET_USER_OFFLINE";
export const SET_USER_AWAY = "SET_USER_AWAY";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const SET_NEW_MULTI_MESSAGE = "SET_NEW_MULTI_MESSAGE";
export const SET_ROOM_PROPERTIES = "SET_ROOM_PROPERTIES";
export const SET_CHAT_ROOM = "SET_CHAT_ROOM";
export const SET_CHAT_SEARCH = "SET_CHAT_SEARCH";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const SET_MESSAGES = "SET_MESSAGES";
export const DELIVERED_MESSAGE = "DELIVERED_MESSAGE";
export const SET_FILES = "SET_FILES";
export const CLEAR_FILES = "CLEAR_FILES";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUCCESS_UPLOAD_FILE = "SUCCESS_UPLOAD_FILE";
export const FAILURE_UPLOAD_FILE = "FAILURE_UPLOAD_FILE";
export const REMOVE_FROM_QUEUE = "REMOVE_FROM_QUEUE";
export const RETRY_UPLOAD_FILE = "RETRY_UPLOAD_FILE";
export const UPDATE_UNREAD = "UPDATE_UNREAD";
export const SET_WIDGET_PROPERTIES = "SET_WIDGET_PROPERTIES";
export const SET_REPLY_MESSAGE = "SET_REPLY_MESSAGE";
export const GET_REPLY_MESSAGE = "GET_REPLY_MESSAGE";
