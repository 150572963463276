import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_ROOM,
  SET_CONTACTS,
  SET_ENTITIES,
  DELETE_ENTITY,
  ADD_CONTACT,
  BLOCK_CONTACT,
  UNBLOCK_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  UPDATE_GUEST,
  SET_GUESTS,
  ADD_GUEST,
  DELETE_GUEST,
  START_CHAT,
  SET_RECIPIENT,
  SET_USER_ONLINE,
  SET_USER_AWAY,
  SET_USER_OFFLINE,
  SET_NEW_MESSAGE,
  SET_NEW_MULTI_MESSAGE,
  SET_CHAT_ROOM,
  SET_CHAT_SEARCH,
  UPDATE_USER,
  SET_ROOM_PROPERTIES,
  SET_WIDGET_PROPERTIES,
  UPDATE_UNREAD,
  REMOVE_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_CONVERSATION,
  DELIVERED_MESSAGE,
  SET_MESSAGES,
  SET_FILES,
  CLEAR_FILES,
  SET_UPLOAD_PROGRESS,
  SUCCESS_UPLOAD_FILE,
  FAILURE_UPLOAD_FILE,
  REMOVE_FROM_QUEUE,
  RETRY_UPLOAD_FILE,
  SET_REPLY_MESSAGE,
  GET_REPLY_MESSAGE,
} from "./constants";

export const chatUser = () => ({
  type: CHAT_USER,
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateGuest = (payload) => ({
  type: UPDATE_GUEST,
  payload,
});

export const setGuests = (payload) => ({
  type: SET_GUESTS,
  payload,
});

export const addGuest = (payload) => ({
  type: ADD_GUEST,
  payload,
});

export const deleteGuest = (payload) => ({
  type: DELETE_GUEST,
  payload,
});

export const setRoomProperties = (payload) => ({
  type: SET_ROOM_PROPERTIES,
  payload: payload,
});

export const setWidgetProperties = (payload) => ({
  type: SET_WIDGET_PROPERTIES,
  payload: payload,
});

export const updateUnread = (uuid, unread, increment = false) => ({
  type: UPDATE_UNREAD,
  payload: {
    uuid,
    unread,
    increment,
  },
});

export const updateConversation = (payload) => ({
  type: UPDATE_CONVERSATION,
  payload: payload,
});

export const updateMessage = (payload) => ({
  type: UPDATE_MESSAGE,
  payload: payload,
});

export const setUploadFile = (payload) => ({
  type: SET_FILES,
  payload: payload,
});

export const clearFileQueue = () => ({
  type: CLEAR_FILES,
});

export const getReplyMsg = (payload) => ({
  type: GET_REPLY_MESSAGE,
  payload: payload,
});

export const setReplyMsg = (payload) => ({
  type: SET_REPLY_MESSAGE,
  payload: payload,
});

export const retryUpload = (id) => ({
  type: RETRY_UPLOAD_FILE,
  payload: id,
});

export const setUploadProgress = (id, progress) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
});

export const successUploadFile = (id) => ({
  type: SUCCESS_UPLOAD_FILE,
  payload: id,
});

export const failureUploadFile = (id) => ({
  type: FAILURE_UPLOAD_FILE,
  payload: id,
});

export const deliveredMessage = (payload) => ({
  type: DELIVERED_MESSAGE,
  payload: payload,
});

export const removeMessage = (msg) => ({
  type: REMOVE_MESSAGE,
  payload: msg,
});

export const setMessages = (payload) => ({
  type: SET_MESSAGES,
  payload: payload,
});

export const activeUser = (userId) => ({
  type: ACTIVE_USER,
  payload: userId,
});

export const setRecipient = (payload) => ({
  type: SET_RECIPIENT,
  payload,
});

export const setFullUser = (fullUser) => ({
  type: FULL_USER,
  payload: fullUser,
});

export const setContacts = (contacts) => ({
  type: SET_CONTACTS,
  payload: contacts,
});

export const setEntities = (entities) => ({
  type: SET_ENTITIES,
  payload: entities,
});

export const deleteEntity = (entity) => ({
  type: DELETE_ENTITY,
  payload: entity,
});

export const addNewChatRoom = (room) => ({
  type: ADD_ROOM,
  payload: room,
});

export const addContact = (contact) => ({
  type: ADD_CONTACT,
  payload: contact,
});

export const updateContact = (payload) => ({
  type: UPDATE_CONTACT,
  payload: payload,
});

export const removeFromQueue = (payload) => ({
  type: REMOVE_FROM_QUEUE,
  payload: payload,
});

export const setUserOnline = (user) => ({
  type: SET_USER_ONLINE,
  payload: user,
});

export const setUserAway = (user) => ({
  type: SET_USER_AWAY,
  payload: user,
});

export const setUserOffline = (user) => ({
  type: SET_USER_OFFLINE,
  payload: user,
});

export const setNewMessage = (payload) => ({
  type: SET_NEW_MESSAGE,
  payload: payload,
});

export const setNewMultiMessage = (payload) => ({
  type: SET_NEW_MULTI_MESSAGE,
  payload: payload,
});

export const setChatRoom = (payload) => ({
  type: SET_CHAT_ROOM,
  payload: payload,
});

export const setChatSearch = (payload) => ({
  type: SET_CHAT_SEARCH,
  payload: payload,
});

export const blockContact = (contact) => {
  return (dispatch) => {
    dispatch({
      type: BLOCK_CONTACT,
      payload: contact,
    });
  };
};

export const unblockContact = (contact) => {
  return (dispatch) => {
    dispatch({
      type: UNBLOCK_CONTACT,
      payload: contact,
    });
  };
};

export const deleteContact = (contact) => ({
  type: DELETE_CONTACT,
  payload: contact,
});

export const startChat = (chat) => ({
  type: START_CHAT,
  payload: chat,
});

export const addLoggedinUser = (userData) => ({
  type: ADD_LOGGED_USER,
  payload: userData,
});

export const createGroup = (groupData) => ({
  type: CREATE_GROUP,
  payload: groupData,
});
